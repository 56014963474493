import React from "react";
import { Link } from 'react-router-dom';

const Banner5 = (props) => {
  let {
    bannerTitle,
    bannerDesc,
    mainImage,
    link1Title,
    link1,
    link2Title,
    link2,
  } = props[0].Info;
  return (
    <section className="section-box">
      <div className="banner-hero banner-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-display-2 color-white">{bannerTitle}</h1>
              <p className="text-body-lead-large color-white mt-30 pr-40">
                {bannerDesc}
              </p>
              {/* <div className="mt-40">
                <Link href={link1} legacyBehavior>
                  <a className="btn btn-pink icon-arrow-right-white text-heading-6">
                    {link1Title}
                  </a>
                </Link>
                <Link href={link2} legacyBehavior>
                  <a className="btn btn-link color-white text-heading-6 btn-link-inter">
                    {link2Title}
                  </a>
                </Link>
              </div> */}
              <div className="mt-60">
                <div className="row">
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-2 color-white mb-15">5000+</h3>
                    <p className="text-body-normal color-gray-300">
                      Happy Clients
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-2 color-white mb-15">756+</h3>
                    <p className="text-body-normal color-gray-300">
                      Project Done
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-2 color-white mb-15">100%</h3>
                    <p className="text-body-normal color-gray-300">
                      Client Satisfaction
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs">
                {/* <a className="popup-youtube btn-play-video-2"></a> */}
                <img
                  className="img-responsive shape-2"
                  alt="Agon"
                  src={`${process.env.REACT_APP_API_URL}/${mainImage}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner5;
