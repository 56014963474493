import { Link } from 'react-router-dom';

const LogoBar1 = (props) => {
  let { img1, img2, img3, img4, img5 } = props[0].Info;
  return (
    <section className="section-box overflow-visible mt-70">
      <div className="container">
        <h2 className="text-heading-3 text-center color-gray-900 mb-60">
          Trusted by the world’s leading companies
        </h2>
        <div className="row">
          <div className="col-lg-12">
            <ul className="list-partners">
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src={`${process.env.REACT_APP_API_URL}/${img1}`}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src={`${process.env.REACT_APP_API_URL}/${img2}`}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-2.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-3.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-4.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-5.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-6.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-7.svg"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#" legacyBehavior>
                  <a className="item-logo box-hover-shadow">
                    <img
                      alt="Agon"
                      src="assets/imgs/slider/logo/sample-logo-8.svg"
                    />
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoBar1;
