import { Link } from 'react-router-dom';

const LogoBar2 = (props) => {
  let { img1, img2, img3, img4, img5 } = props[0].Info;
  return (
    <div className="section-box overflow-visible mt-70">
    <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon"  src={`${process.env.REACT_APP_API_URL}/${img1}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon"  src={`${process.env.REACT_APP_API_URL}/${img2}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon"  src={`${process.env.REACT_APP_API_URL}/${img3}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon"  src={`${process.env.REACT_APP_API_URL}/${img4}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon"  src={`${process.env.REACT_APP_API_URL}/${img5}`} /></a></Link>
            </div>
            {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link href="/#" legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-6.svg" /></a></Link>
            </div> */}
        </div>
    </div>
</div>
  );
};

export default LogoBar2;
