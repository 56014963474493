import React from 'react';
import { Link } from 'react-router-dom';

const Banner1 = (props) => {
    let {banner1Title, banner1Desc, banner1Img, linkTitle1, link1, linkTitle2, link2} = props[0].Info;
    return (
        <section className="section-box">
        <div className="banner-hero banner-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <h1 className="text-display-2">
                            {/* We are
                            <span className="color-green-900">awesome team</span> */}
                            {banner1Title}
                        </h1>
                        <p className="text-body-lead-large color-gray-500 mt-40 pr-40">
                            {banner1Desc}
                        </p>
                        {/* <div className="mt-40">
                            <Link href={link1} legacyBehavior><a className="btn btn-black icon-arrow-right-white">{linkTitle1}</a></Link>
                            <Link href={link2} legacyBehavior><a className="btn btn-link icon-arrow-right color-gray-900 text-heading-6">{linkTitle2}</a></Link>
                        </div> */}
                    </div>
                    <div className="col-lg-5 d-none d-lg-block">
                        <div className="banner-imgs">
                            <div className="video-block shape-1">
                                <a className="popup-youtube btn-play-video" ></a>
                                {/* onClick={openModal} */}
                                <span className="text-heading-4">Watch intro video</span>

                            </div>
                            <img className="img-responsive shape-2" alt="Agon" src={`${process.env.REACT_APP_API_URL}/${banner1Img}`} />
                            {/* assets/imgs/page/homepage1/banner.png */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Banner1;