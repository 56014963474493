import React, { useState, useEffect } from "react";
import fetchAdditionalData from "./fetchAdditionalData";
import axios from "axios";
import Error404 from "../components/Error404";
const apiUrl = process.env.REACT_APP_API_URL;
const websiteId = process.env.websiteId;

const DynamicPage = ({ componentMap, pageName, pageId }) => {
  console.log('HELLO')
  console.log(componentMap, pageName, pageId);
  const [myPageConfig, setMyPageConfig] = useState([]);
  const [error404, setError404] = useState(false);
  useEffect(() => {
    const fetchPageConfig = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/GetPage?websiteId=20cac4fe-d0ab-4ce2-a1ab-08dccf41acf5&pageName=${pageName}`
        );
        if (!response.data) setError404(true);
        const componentList = response.data;
        const updatedConfig = await Promise.all(
          componentList.map(async (item) => {
            if (item.Type) {
              const additionalData = await fetchAdditionalData(
                item.Info,
                item.Props,
                pageId
              );
              return { ...item, Props: [additionalData] };
            }
            return item;
          })
        );

        setMyPageConfig(updatedConfig);
      } catch (error) {
        console.error("Error fetching page config:", error);
      }
    };

    fetchPageConfig();
  }, []);

  return (
    <div>
      {myPageConfig.length === 0 ? (
        error404 ? (
          <Error404 />
        ) : (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Spinner */}
            <div className="spinner" style={{ marginRight: "10px" }}>
              <div className="double-spinner"></div> {/* Adding a spinner */}
            </div>

            {/* Logo */}
            <img
              style={{ filter: `grayscale(100%)`, height: "100px" }} // Adjust logo size if necessary
              src="./nLogo.png"
              alt="Loading..."
              className="loading-logo"
            />
          </div>
        )
      ) : (
        myPageConfig.map((item, index) => {
          const { ComponentName, Props } = item;
          const Component = componentMap[ComponentName];

          if (!Component) {
            console.warn(`Component "${ComponentName}" not found.`);
            return null;
          }

          return <Component key={index} {...Props} />;
        })
      )}
    </div>
  );
};

export default DynamicPage;
