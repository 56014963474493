import React from "react";
import { Link } from 'react-router-dom';

const Banner2 = (props) => {
  let {
    banner2Title,
    banner2Desc,
    mainImage,
    smallImage1,
    smallImage2,
    link1Title,
    link1,
    link2Title,
    link2,
  } = props[0].Info;
  return (
    <section className="section-box">
      <div className="banner-hero banner-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-50">
              {/* <span className="tag-1 bg-green-900">
                Digital Marketing Agency
              </span> */}
              <h1 className="text-display-3 mt-30">{banner2Title}</h1>
              <p className="text-body-lead-large color-gray-500 mt-40 pr-40">
                {banner2Desc}
              </p>
              {/* <div className="mt-40">
                <Link href={link1} legacyBehavior>
                  <a className="btn btn-black shape-square icon-arrow-right-white">
                    {link1Title}
                  </a>
                </Link>
                <Link href={link2} legacyBehavior>
                  <a className="btn btn-link icon-triangle color-gray-900 ml-40">
                    {link2Title}
                  </a>
                </Link>
              </div> */}
              <div className="mt-50">
                <div className="row">
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      +<span className="count">38</span>k
                    </h3>
                    <p className="text-body-text-md color-gray-500">
                      Happy Clients
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      +<span className="count">45</span>k
                    </h3>
                    <p className="text-body-text-md color-gray-500">
                      Project Done
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      <span className="count">100</span>%
                    </h3>
                    <p className="text-body-text-md color-gray-500">
                      Client Satisfaction
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs">
                <div className="block-1 shape-2">
                  <img src={`${process.env.REACT_APP_API_URL}/${smallImage1}`} alt="Agon" />
                </div>
                <div className="block-2 shape-3">
                  <img src={`${process.env.REACT_APP_API_URL}/${smallImage2}`} alt="Agon" />
                </div>
                <img
                  className="img-responsive shape-1"
                  alt="Agon"
                  src={`${process.env.REACT_APP_API_URL}/${mainImage}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner2;
