import React from "react";
import { useState } from "react";

const AboutUsSection = (props) => {
  let { sectionTitle, vision, mission, values } = props[0].Info;
  return (
    <section className="section-box mt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-1 col-sm-1 col-12" />
          <div className="col-lg-10 col-sm-10 col-12 text-center">
            <h2 className="text-heading-1 color-gray-900 mb-10">
              About Us
            </h2>
            <p className="text-body-lead-large color-gray-600 mt-20">
              {sectionTitle}
            </p>
          </div>
          <div className="col-lg-1 col-sm-1 col-12" />
        </div>
      </div>
      <div className="container mt-40">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="list-icons mt-50">
              <div className="item-icon none-bd">
                <span className="icon-left">
                  <img
                    src="/assets/imgs/page/homepage2/icon-acquis.svg"
                    alt="Agon"
                  />
                </span>
                <h4 className="text-heading-4">Our Vision</h4>
                <p className="text-body-text color-gray-600 mt-15">
                  {vision}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="list-icons mt-50">
              <div className="item-icon none-bd">
                <span className="icon-left">
                  <img
                    src="/assets/imgs/page/homepage2/icon-active.svg"
                    alt="Agon"
                  />
                </span>
                <h4 className="text-heading-4">Our Mission</h4>
                <p className="text-body-text color-gray-600 mt-15">
                  {mission}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="list-icons mt-50">
              <div className="item-icon none-bd">
                <span className="icon-left">
                  <img
                    src="/assets/imgs/page/homepage2/icon-retent.svg"
                    alt="Agon"
                  />
                </span>
                <h4 className="text-heading-4">Our Values</h4>
                <p className="text-body-text color-gray-600 mt-15">
                  {values}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
