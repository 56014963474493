// App.js
import React, { useEffect, useState } from "react"; // Import useState and useEffect
import { useLocation } from "react-router-dom";
// Import global CSS files
// In App.js or index.js
import "./assets/css/style.css";
import "./assets/css/modal.css";
import "./assets/css/swiper-custom.css";
import "./assets/css/logo.css";

// Import all your components
import DynamicPage from "./DesginComponent/DynamicPage";
import Header from "./components/Header";
import Banner1 from "./components/banners/Banner1";
import Banner2 from "./components/banners/Banner2";
import Banner5 from "./components/banners/Banner5";
import ServicesBar from "./components/ServicesBar";
import Footer from "./components/Footer";
import CardList from "./components/CardList";
import NewsLetter from "./components/NewsLetter";
import BlogList from "./components/blog/BlogList";
import About from "./components/About";
import SingleBlog from "./components/SingleBlog";
import ContactBanner from "./components/contact/ContactBanner";
import ContactForm from "./components/contact/ContactForm";
import Faq1 from "./components/Faq1";
import Faq2 from "./components/Faq2";
import NewsBar from "./components/NewsBar";
import LogoBar1 from "./components/LogoBar1";
import LogoBar2 from "./components/LogoBar2";
import BlogSlider from "./components/slider/Blog";
import BlogBanner from "./components/blog/BlogBanner";
import AboutBanner from "./components/AboutBanner";
import AboutUsSection from "./components/AboutUsSection";
import MiddleSection1 from "./components/MiddleSection1";
import MiddleSection2 from "./components/MiddleSection2";
import MiddleService from "./components/MiddleService";

const componentMap = {
  Header,
  Banner1,
  Banner2,
  Banner5,
  ServicesBar,
  Footer,
  CardList,
  NewsLetter,
  BlogList,
  About,
  SingleBlog,
  BlogSlider,
  BlogBanner,
  ContactBanner,
  ContactForm,
  Faq1,
  Faq2,
  NewsBar,
  LogoBar1,
  LogoBar2,
  AboutBanner,
  AboutUsSection,
  MiddleSection1,
  MiddleSection2,
  MiddleService,
};

const App = () => {
  const [pageName, setPageName] = useState("");
  const [id, setId] = useState("");

  const location = useLocation(); // Access the current location object
  const pathname = location.pathname; 
  console.log('pathname', pageName)
  const pathSegments = pathname.split("/").filter(Boolean); // Remove empty strings

  if (pathSegments.length >= 2) {
    setPageName(pathSegments[0]); // First segment is the endpoint
    setId(pathSegments[1]); // Second segment is the ID
  }
  
  return (
    <div>
      <DynamicPage
        componentMap={componentMap}
        pageName={pathSegments[0] || "home"}
        pageId={pathSegments[1] || null}
      />

      <script
        src="https://code.tidio.co/b6mxnlcagr6dqz6eskcph3qtj1wbxsre.js"
        async
      ></script>
    </div>
  );
};

export default App;
