import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import Sidebar from "./layout/Sidebar";
import BackToTop from "./elements/BackToTop";

const Header = (props) => {
  const [openClass, setOpenClass] = useState("");

  const handleOpen = () => {
    document.body.classList.add("mobile-menu-active");
    setOpenClass("sidebar-visible")
}

const handleRemove = () => {
    if (openClass === "sidebar-visible") {
        setOpenClass("")
        document.body.classList.remove("mobile-menu-active");
    }
}
  let { headerStyle } = props;
  let { Logo, SmallLogo, shadePercentage } = props[0].Info;
  let links = props[0].Links;
  console.log(links);
  const [scroll, setScroll] = useState(0);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });
  return (
    <>
      <div className={openClass && "body-overlay-1"} onClick={handleRemove} />
      <header
        className={
          scroll
            ? `${headerStyle} header sticky-bar stick `
            : `${headerStyle} header sticky-bar`
        }
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link href="/" legacyBehavior>
                  <a className="d-flex">
                    {headerStyle ? (
                      <img
                        alt="Agon"
                        src={`${process.env.REACT_APP_API_URL}/${SmallLogo}`}
                        style={{ filter: `grayscale(${shadePercentage}%)` }}
                      />
                    ) : (
                      <img
                        alt="Agon"
                        src={`${process.env.REACT_APP_API_URL}/${Logo}`}
                        style={{ filter: `grayscale(${shadePercentage}%)` }}
                        width={250}
                      />
                    )}
                  </a>
                </Link>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  <ul className="main-menu">
                    {links.map((link) => (
                      <li key={link.Name}>
                        <a href={link.Url} legacyBehavior>
                          <a className="active">{link.Name}</a>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div
                  className="burger-icon burger-icon-white"
                  onClick={handleOpen}
                >
                  <span className="burger-icon-top" />
                  <span className="burger-icon-mid" />
                  <span className="burger-icon-bottom" />
                </div>
              </div>
            </div>
            <div className="header-right">
              <div className="block-signin">
                <Link href="/contact" legacyBehavior>
                  <a className="btn btn-default hover-up icon-arrow-right">
                    Contact us
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar openClass={openClass} />

      {/* <Sidebar openClass={openClass} />
            <main className="main">
                {children}
            </main> */}
    </>
  );
};

export default Header;
