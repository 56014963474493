import { Link } from 'react-router-dom';

const CardList = (props) => {
  let data = props[0].Data;
  let {serviceTitle} = props[0].Info
  return (
    <section className="section-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-sm-1 col-12" />
          <div className="col-lg-8 col-sm-10 col-12 text-center mt-100">
            <h2 className="text-heading-1 color-gray-900">
              We facilitate the creation
              <br className="d-lg-block d-none" />
              of strategy and design
            </h2>
            <p className="text-body-lead-large color-gray-600 mt-20">
              {serviceTitle}
            </p>
          </div>
          <div className="col-lg-2 col-sm-1 col-12" />
        </div>
      </div>
      <div className="container mt-70">
        <div className="row">
          {data.map((item, i) => (
            <div className="col-lg-4 col-sm-12">
              <div className="card-grid-1 bg-5 bg-business hover-up">
                <div className="grid-1-img">
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/uploads/" +
                      item.ImageUrl
                    }
                    alt="Agon"
                  />
                </div>
                <h3 className="text-heading-3 mt-20">{item.TitleEn}</h3>
                <p className="text-body-excerpt mt-20">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.DescriptionEn }}
                  />
                </p>
                <div className="mt-30">
                  <Link href="/page-about-1" legacyBehavior>
                    <a className="btn btn-default btn-white icon-arrow-right">
                      Learn more
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardList;
