import axios from "axios";
const url = process.env.REACT_APP_API_URL;
const fetchAdditionalData = async (info, props, id) => {
  try {
    let result = {};

    console.log(props)
    result.Data = await getData(props.Table, props.AllData, id);
    result.Info = JSON.parse(info);
    if(result.Info) result.Info = result.Info.reduce((acc, info) => {
      acc[info.Key] = info.Value;
      return acc;
    }, {});

    if (props.Links) result.Links = props.Links;

    return result;
  } catch (error) {
    console.error("Error fetching additional data:", error);
    return null;
  }
};

async function getInfo() {
  const response = await axios.get(
    `${url}/api/GetSiteInfo?websiteId=20cac4fe-d0ab-4ce2-a1ab-08dccf41acf5`
  );

  const reshapedObject = response.data.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

  return reshapedObject;
}

async function getData(table, type, id) {
  const response = await axios.get(
    `${url}/api/LoadTableData?table=${table}&type=${type}&id=${id}`
  );
  return response.data;
}
export default fetchAdditionalData;
