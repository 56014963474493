import React from "react";
import { useState } from "react";

const AboutBanner = (props) => {

  let { aboutTitle, aboutBannerImg } = props[0].Info;
  return (
    <>
      <section className="section-box">
        <div className="banner-hero bg-about-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="text-display-2 color-gray-900">Our Story</h1>
                <p className="text-heading-4 color-gray-600 mt-40">
                  {aboutTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-box mt-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <div className="box-image">
                

                <img
                  className="img-responsive bdrd-16"
                  src={`${process.env.REACT_APP_API_URL}/${aboutBannerImg}`}
                  alt="Agon"
                />
              </div>
            </div>
            <div className="col-lg-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;
