import React from "react";
import { Link } from 'react-router-dom';

const About = (props) => {
  return (
    <section className="section-box">
      <div className="banner-hero banner-2 bg-about-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <span className="tag-1 color-orange-900">
                What We Do, What You Get
              </span>
              <h1 className="text-display-3 mt-30">
                A small creative team excited to create beautiful things
              </h1>
              <p className="text-body-lead-large color-gray-500 mt-40 pr-40">
                Integrated workflow designed for product teams. We create
                world-class development and branding
              </p>
              <div className="mt-40">
                <Link href="/page-service-1" legacyBehavior>
                  <a className="btn btn-black shape-square icon-arrow-right-white">
                    Join Our Team
                  </a>
                </Link>

                <Link href="/page-contact" legacyBehavior>
                  <a className="btn btn-link color-gray-900 icon-arrow-right text-heading-6">
                    Contact Us
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs">
                <div className="block-1 shape-1">
                  <img src="/assets/imgs/page/about/1/banner2.png" alt="Agon" />
                </div>
                <img
                  className="img-responsive shape-2"
                  alt="Agon"
                  src="assets/imgs/page/about/1/banner1.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
